<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="buttonColor"
        dark
        v-bind="attrs"
        v-on="on"
        v-if="!isUpdate"
      >
        {{'課金ステータスの新規追加'}}
      </v-btn>
      <v-btn
        :color="buttonColor"
        dark
        v-bind="attrs"
        v-on="on"
        v-if="isUpdate"
      >
        {{'更新'}}
      </v-btn>
      <v-btn
        color="red"
        v-if="isUpdate && isMasters"
        @click="onClickDeletePlan"
      >
        {{'削除(プラン変更)'}}
      </v-btn>
    </template>
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title>
          <span class="text-h5">{{isUpdate ? '課金ステータスの更新' : '課金ステータスの追加'}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="user.username"
                  readonly
                  label="ユーザー名(変更不可)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="selectPrimeType"
                  :items="primeTypeItemList" 
                  label="課金ステータス(変更不可)"
                  v-if="isUpdate"
                  readonly
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="selectPrimeType"
                  :items="primeTypeItemList"
                  v-if="!isUpdate"
                  label="課金ステータス" 
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="startDate"
                  label="利用開始日時(変更不可)"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-menu
                  v-model="expire_menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="expire"
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      label="有効期限"
                      @click:clear="expire = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="expire"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            @click="onClickDelete"
          >
            閉じる
          </v-btn>
          <v-btn
            v-if="!isUpdate"
            color="primary"
            dark
            @click="onClickCreate"
          >
            新規追加
          </v-btn>
          <v-btn
          v-if="isUpdate"
            color="primary"
            dark
            @click="onClickUpdate"
          >
            更新
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

// 課金ステータスダイアログ
export default {
  name: "PaidUserDialog",
  components: {
  },
  props: {
    user: Object(),
    item: Object(),
    primeTypeList: Array()
  },
  mounted() {
    this.loading = true;
  },
  data() {
    return {
      isActive: false,
      isActiveItems: [
        {'text':"有効", 'value':true},
        {'text':"無効", 'value':false}
      ],
      selectPrimeType: "",
      startDate: "",
      expire: "",
      expire_menu: "",
      valid: false,
      dialog: false
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.item) {
          this.selectPrimeType = this.item["prime_type"];
          if (this.item["start_date"]) {
            this.startDate = new Date(this.item["start_date"]).toISOString().substr(0, 10);
          }
          if (this.item["expire"]) {
            this.expire = new Date(this.item["expire"]).toISOString().substr(0, 10);
          }
        }
      }
    }
  },
  computed: {
    isUpdate(){
      return this.item != undefined;
    },
    primeTypeItemList () {
      if (!this.primeTypeList) return [];
      return this.primeTypeList.filter((primeType) => {
        if (this.user.custom_user_type == 1) { // farmers
          const has_no = this.user.paid_user_list.filter((paidUser) => {
            return primeType.id == paidUser.prime_type;
          });
          return has_no.length < 1 && primeType.id <= 2;
        } else if (this.user.custom_user_type == 3) { // masters
          return primeType.id >= 3 && primeType.id <= 10
        }
        return true;
      }).map((primeType) => {
        return {
          "text": primeType.name,
          "value": primeType.id
        };
      });
    },
    buttonColor(){
      return this.isUpdate ? "" : "primary"
    },
    isMasters(){
      return this.user.custom_user_type == 3
    },
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    formReset () {
      return this.$refs.form.reset()
    },
    showModal () {
      this.dialog = true;
    },
    closeModal () {
      this.dialog = false;
    },
    getPaidUserValue(paidUserList, primeTypeId) {
      if (!paidUserList && paidUserList.length < 1) return;
      const filterValue = paidUserList.filter((paidUser) => paidUser.prime_type == primeTypeId);
      if (filterValue.length < 1) return;
      return filterValue[0];
    },
    onClickUpdate () {
      if (this.validate()) {
        this.loading = true;
        const userId = this.user.id;
        const postData = {
          'user': userId,
          'prime_type': this.selectPrimeType,
          'start_date': null,
          'expire': null
        };
        if (this.startDate) {
          postData["start_date"] = new Date(this.startDate).toISOString();
        }
        if (this.expire) {
          postData["expire"] = new Date(this.expire).toISOString();
        }
        
        axios.put(`/api/admin/paid_users/${this.item["id"]}/`, postData).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          Swal.fire({
            icon: 'info',
            title: '更新完了',
            text: 'ユーザーの更新が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
          this.$emit('reload')
        }).catch(() => {
          this.loading = false;
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
        })
      }
    },
    onClickCreate() {
      if (this.validate()) {
        this.loading = true;
        const userId = this.user.id;
        const postData = {
          'user': userId,
          'prime_type': this.selectPrimeType
        };
        if (this.startDate) {
          postData["start_date"] = new Date(this.startDate).toISOString();
        }
        if (this.expire) {
          postData["expire"] = new Date(this.expire).toISOString();
        }
        
        axios.post(`/api/admin/paid_users/`, postData).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          Swal.fire({
            icon: 'info',
            title: '更新完了',
            text: '課金ステータスの追加が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
          this.$emit('reload')
        }).catch(() => {
          this.loading = false;
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
        })
      }
    },
    onClickDelete() {
      Swal.fire({
        icon: 'warning',
        title: '変更途中の退出',
        text: '変更が保存されないまま閉じられようとしていますがよろしいですか？',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = false
        }
      })
    },
    onClickDeletePlan() {
      Swal.fire({
        icon: 'warning',
        title: 'プランの削除',
        text: 'プランを削除します。プランを変更する場合は削除後に課金ステータスの追加を行う必要があります。本当によろしいですか？',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/admin/paid_users/${this.item["id"]}/`).then(() => {
            Swal.fire({
              icon: 'info',
              title: '削除完了',
              text: 'プランの削除が完了しました。プランを変更する場合は課金ステータスの追加をしてください。',
              showConfirmButton:true,
              showCloseButton:false,
              timer:3000
            })
            this.$emit('reload')
          })
          this.dialog = false
        }
      })
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
