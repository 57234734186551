<template>
  <v-container fluid >
    <v-card class="d-flex flex-column mx-auto">
      <v-card-text class="d-flex justify-center flex-column">
        <v-card-title class="d-flex justify-center pa-0">
          登録NDVIデータの閲覧
        </v-card-title>
      </v-card-text>
    </v-card>
    <v-row class="my-3">
      <v-col sm="12" md="12" lg="4">
        <v-card class="d-flex flex-column mx-auto">
          <v-card-actions>
            <v-select
              v-model="selectUser"
              :items="usersItemList"
              :rules="[v => !!v || 'ユーザーを選択してください。']"
              label="ユーザー選択"
              v-on:change="onSelectUser"
              required
            ></v-select>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col sm="12" md="12" lg="4">
        <v-card class="d-flex flex-column mx-auto">
          <v-card-actions>
            <v-select
              v-model="selectCrop"
              :items="cropItemList"
              :rules="[v => !!v || '作物を選択してください']"
              label="作物"
              v-on:change="onSelectCrop"
              required
            ></v-select>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col sm="12" md="12" lg="4">
        <v-card class="d-flex flex-column mx-auto">
          <v-card-actions>
            <v-select
              v-model="selectPlantMaster"
              :items="plantMasterItemList"
              :rules="[v => !!v || '栽培グループを選択してください']"
              label="栽培グループ"
              v-on:change="onSelectPlantMaster"
              required
            ></v-select>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col sm="12" md="12" lg="4">
        <v-card class="d-flex flex-column mx-auto">
          <v-card-actions>
            <v-select
              v-model="selectGroupYear"
              :items="groupYearItemList"
              :rules="[v => !!v || '年度を設定してください']"
              label="年度"
              v-on:change="onSelectGroupYear"
              required
            ></v-select>
            <v-btn icon
              @click.stop="downloadGroupYearGeojson"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="d-flex mx-auto plant-info-area overflow-y-hidden" max-height="180">
      <v-card 
        v-for="plantInfo in displayPlantInfoList"
        :key="plantInfo.id"
        min-width="200"
        class="d-flex"
      >
        <v-card-text class="d-flex justify-center flex-column">
          <v-card-subtitle>
            圃場名：{{ plantInfo.field.name }} <br/>
            作物名：{{ getCropName(plantInfo) }} <br/>
            定植日：{{ displayDate(plantInfo.plant_date) }} <br/>
            定植日：{{ displayDate(plantInfo.harvest_date) }}
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon
              @click.stop="downloadPlantInfoGeojson(plantInfo)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn icon
              @click.stop="flyTo(plantInfo.field.id)"
            >
              <v-icon>mdi-map-marker</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-card>
    <v-row dense class="mx-auto">
      <v-col sm="12" md="12" lg="12">
        <div class="viewer">
          <vc-viewer @ready="ready" :infoBox="false" :selectionIndicator="false">
            <vc-layer-imagery>
              <vc-provider-imagery-arcgis-mapserver :url="url" />
            </vc-layer-imagery>
            <vc-entity v-for="polygon in displayPolygons" :key="polygon.id" @click="onPolygonClick(polygon)">
              <vc-graphics-polygon
                :hierarchy="convertHierarchy(polygon)"
                :material="[0.1, 1, 0.2, 0.5]"
                :extrudedHeight="0.0"
                :closeTop="false"
                :closeBottom="false"
                :ref="polygonRef(polygon.id)"
                :outline="true"
                :outlineColor="[0.1, 1, 0.2, 1]"
                :outlineWidth="5"
              ></vc-graphics-polygon>
            </vc-entity>
          </vc-viewer>
        </div>
      </v-col>
    </v-row>
    <ChartDialog ref="chartDialog" :chartData="chartData" :chartLabels="chartLabels" :plantInfo="plantInfo" :title="displayChartTitle" @close="onChartDialogClose">
    </ChartDialog>
    <LoadingDialog ref="loadingDialog">
    </LoadingDialog>
  </v-container>
</template>

<script>
import ChartDialog from '@/components/comps/ChartDialog'
import LoadingDialog from '@/components/comps/LoadingDialog'
import router from "../../router";
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: "Viewer",
  components: {
    ChartDialog,
    LoadingDialog
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
      url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer",
      Cesium: {},
      viewer: {},
      camera: {},
      selectUser: "",
      users: [],
      usersItemList: [],
      selectPlantMaster: "",
      plantMasterList: [],
      plantMasterItemList: [],
      selectGroupYear: "",
      groupYearItemList: [],
      selectCrop: '',
      cropItemList: [],
      selectPolygon: "",
      plantInfo: {},
      ndviData: [],
      ndviAvg: [],
      ndviAreaGroupAvg: [],
      fields: [],
      polygons: []
    }
  },
  methods: {
    ready(cesiumInstance) {
      const { Cesium, viewer } = cesiumInstance;
      viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      this.Cesium = Cesium;
      this.viewer = viewer;
    },
    reload() {
      this.checkLoggedIn();

      axios.get(`/api/admin/users/?is_customer=true&custom_user_type=1`).then((res) => {
        this.users = res.data;
        this.usersItemList = res.data.map((data)=>{
          return {'text':`${data.id}: ${data.username}: ${data.email}`, 'value':data.id};
        });
        
        if (!this.usersItemList || this.usersItemList.length < 1) return;

        this.selectUser = this.usersItemList[0].value;
        this.onSelectUser();
      });
    },
    onSelectUser() {
      const user = this.users.filter((user) => user.id == this.selectUser)[0];
      this.groupYearItemList = [];
      this.selectPolygon = "";
      this.selectGroupYear = "";
      this.selectPlantMaster = "";
      this.selectPolygon = "";
      this.ndviAvg = [];
      this.ndviAreaGroupAvg = [];
      this.ndviData = [];

      this.$refs['loadingDialog'].showModal();
      Promise.all([
        axios.get(`/api/admin/crops/?user_id=${this.selectUser}`).then((res) =>{
          this.cropItemList = res.data.map((crop) => {
            return {'text':`${crop.name}`, 'value':crop.id};
          });
        }),
        axios.get(`/api/plant_master/?user_id=${this.selectUser}`).then((res) => {
          this.plantMasterList = res.data.filter((plantMaster) => {
            if (!plantMaster.group_year_list) return false;
            if (plantMaster.group_year_list.length < 1) return false;

            const gropuYears = plantMaster.group_year_list.filter(groupYear => groupYear.plant_info_list.length > 0);
            if (gropuYears.length < 1){
              return false;
            }
            return true;
          });

          this.plantMasterItemList = this.plantMasterList.map((plantMaster) => {
            return {'text':`${plantMaster.name}`, 'value':plantMaster.id};
          });
          if (!this.plantMasterItemList || this.plantMasterItemList.length < 1) return;
          // = this.plantMasterItemList[0].value;
          this.onSelectPlantMaster();
        }),
        axios.get(`/api/geoapi_from_postal/?zipcode=${user.zipcode}`).then((res) => {
          this.zoomX = res.data.x;
          this.zoomY = res.data.y;
          this.zoom();
        }),
        axios.get(`/api/fields/?user_id=${this.selectUser}`).then((res) => {
          this.fields = res.data;
        }),
        axios.get(`/api/polygon_detail/?user_id=${this.selectUser}`).then((res) => {
          this.polygons = res.data;
          this.polygons.forEach((polygon) => {
            polygon.poslist.sort((a, b) => a.sequence - b.sequence);
          })
        })
      ]).then(()=>{
        this.$refs['loadingDialog'].closeModal();
      });
    },
    zoom(){
      if (Object.keys(this.viewer).length){
        this.viewer.camera.flyTo({destination:this.Cesium.Cartesian3.fromDegrees(this.zoomX, this.zoomY, 12000.0)})
      } else {
        setTimeout(this.zoom, 100);
      }
    },
    getCropName (plantInfo) {
      const plantMaster = this.plantMasterList.filter((plantMaster) => plantMaster.id == plantInfo.plant_master)[0];
      return plantMaster.crop.name;
    },
    getPolygon(field_id) {
      return this.polygons.filter((polygon)=>{
        return polygon.field && polygon.field.id == field_id;
      });
    },
    flyTo(field_id) {
      const result = this.getPolygon(field_id);
      const pos = result[0].poslist[0];
      this.viewer.camera.flyTo({destination:this.Cesium.Cartesian3.fromDegrees(pos.lng, pos.lat, 1000.0)});
    },
    onSelectCrop() {
      const plantMasters = this.plantMasterList.filter((plantMaster) => plantMaster.crop.id == this.selectCrop);
      this.selectPlantMaster = '';
      this.onSelectPlantMaster();
      if (plantMasters.length < 1) {
        this.plantMasterItemList = [];
        return;
      }
      this.plantMasterItemList = plantMasters.map((plantMaster) => {
        return {'text':`${plantMaster.name}`, 'value':plantMaster.id};
      });
    },
    onSelectPlantMaster() {
      const plantMaster = this.plantMasterList.filter((plantMaster) => plantMaster.id == this.selectPlantMaster)[0];
      this.selectGroupYear = '';
      if (!plantMaster) {
        this.groupYearItemList = [];
        return;
      }
      this.groupYearItemList = plantMaster.group_year_list.map((groupYear) => {
        const yearStr = new Date(groupYear.date).toISOString().substr(0, 4);
        return {'text':`${yearStr}`, 'value':groupYear.id};
      });
      if (!this.groupYearItemList || this.groupYearItemList.length < 1) return;
      this.selectGroupYear = this.groupYearItemList[0].value;
      this.onSelectGroupYear();
    },
    onSelectGroupYear() {
    },
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
    convertHierarchy(polygon) {
      return polygon.poslist.map((pos) => {
        return { "lng": pos.lng, "lat": pos.lat, height: 0 }
      });
    },
    polygonRef(polygon_id) {
      return `polygon_${polygon_id}`;
    },
    onPolygonClick(polygon) {
      const plantInfo = this.getPlantInfo(polygon.field.id);
      if (!plantInfo) {
        console.log("data none plant info = "+plantInfo.id);
        return;
      }
      
      this.$refs['loadingDialog'].showModal();
      Promise.all([
        axios.get(`/api/ndvi_group_year_avg/?plant_master_id=${plantInfo.plant_master}&group_year_id=${plantInfo.group_year}`).then((res) => {
          if (res.data[0]) {
            this.ndviAreaGroupAvg = res.data[0];
          }
        }),
        axios.get(`/api/ndvi_avg/?plant_master_id=${plantInfo.plant_master}`).then((res) => {
          if (res.data[0]) {
            this.ndviAvg = res.data[0];
          }
        }),
        axios.get(`/api/ndvi/?plant_info_id=${plantInfo.id}&is_valid=True`).then((res) => {
          this.ndviData = res.data;
          this.ndviData.sort((a, b) => a.elapse_date - b.elapse_date)
        })
      ]).then(()=>{
        this.selectPolygon = polygon.id;
        this.plantInfo = plantInfo;
        this.$refs['loadingDialog'].closeModal();
        this.$refs['chartDialog'].showModal();
      })
    },
    onChartDialogClose() {
      this.selectPolygon = "";
      this.plantInfo = "";
    },
    downloadGroupYearGeojson() {
      const plantMaster = this.plantMasterList.filter((plantMaster) => plantMaster.id == this.selectPlantMaster)[0];
      const groupYear = plantMaster.group_year_list.filter((groupYear) => groupYear.id == this.selectGroupYear)[0];
      const name = `${plantMaster.name}:${this.displayDate(groupYear.date)}`
      axios.get(`/api/group_year_geo_json/?group_year_id=${this.selectGroupYear}`).then((res) => {
        if (res.data[0]) {
          // Blobオブジェクト生成
          const jsonBlob = new Blob([res.data], {type : "application/json;charset=utf-8;"});
          this.downloadBlob(jsonBlob, name, "geojson");
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'データを取得できませんでした',
            showConfirmButton:false,
            showCloseButton:false,
            timer:3000
          })
        }
      }).catch(()=>{
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          text: 'サーバーエラーが発生しました',
          showConfirmButton:false,
          showCloseButton:false,
          timer:3000
        })
      })
    },
    downloadPlantInfoGeojson(plantInfo) {
      axios.get(`/api/field_geo_json/?plant_info_id=${plantInfo.id}`).then((res) => {
        if (res.data[0]) {
          // Blobオブジェクト生成
          const jsonBlob = new Blob([res.data], {type : "application/json;charset=utf-8;"});
          this.downloadBlob(jsonBlob, plantInfo.field.name, "geojson");
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'データを取得できませんでした',
            showConfirmButton:false,
            showCloseButton:false,
            timer:3000
          })
        }
      }).catch(()=>{
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          text: 'サーバーエラーが発生しました',
          showConfirmButton:false,
          showCloseButton:false,
          timer:3000
        })
      })
    },
    downloadBlob (blob, title, ext) {
      const downloadUrl = window.URL.createObjectURL(blob);

      // ダウンロードリンク生成
      const link = document.createElement("a");
      link.href = downloadUrl;

      // ファイル名
      link.download =
          `${title}`
          + "." + ext;

      // ダウンロード実行
      document.body.appendChild(link);
      link.click();

      // ダウンロードリンク破棄
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    },
    getPlantInfo(fieldId) {
      return this.displayPlantInfoList.filter(plantInfo => plantInfo.field.id == fieldId)[0];
    },
    getPointColor(ndvi) {
      if (ndvi.status == -1) return "#FF00FF";
      if (ndvi.status == 1) return "#00FFFF";
      return "#00FF00"
    },
    displayDate(date) {
      return new Date(date).toISOString().substr(0, 10);
    }
  },
  computed : {
    displayChartTitle() {
      if (!Object.keys(this.plantInfo).length) return "";
      const plantMaster = this.plantMasterList.filter((plantMaster) => plantMaster.id == this.plantInfo.plant_master)[0];
      return `${plantMaster.name}_${this.plantInfo.field.name}_${plantMaster.crop.name}_${this.displayDate(this.plantInfo.plant_date)}_${this.displayDate(this.plantInfo.harvest_date)}`;
    },
    displayPlantInfoList() {
      if (!this.selectPlantMaster) return [];
      if (!this.selectGroupYear) return [];
      const plantMaster = this.plantMasterList.filter((plantMaster) => plantMaster.id == this.selectPlantMaster)[0];
      const groupYear = plantMaster.group_year_list.filter((groupYear) => groupYear.id == this.selectGroupYear)[0];
      return groupYear.plant_info_list;
    },
    displayPolygons () {
      if (!this.selectPlantMaster) return [];
      if (!this.selectGroupYear) return [];
      const displayPolygon = this.polygons.filter((polygon) => {
        const findPolygon = this.displayPlantInfoList.filter((plantInfo)=> {
          return plantInfo.field.id == polygon.field.id;
        });
        return findPolygon.length > 0;
      });
      return displayPolygon;
    },
    chartLabels(){
      let elapse_date = [];
      if (!this.selectPolygon) {
        let NAelapse_date = [];
        let NYGAelapse_date = [];
        if (Object.keys(this.ndviAvg).length > 0 && this.ndviAvg.ndvi_avg_value_list.length > 0) {
          NAelapse_date = this.ndviAvg.ndvi_avg_value_list.map((ndvi_avg_value)=>ndvi_avg_value.elapse_date)
        }
        if (Object.keys(this.ndviAreaGroupAvg).length > 0 && this.ndviAreaGroupAvg.ndvi_group_year_avg_value_list.length > 0) {
          NYGAelapse_date = this.ndviAreaGroupAvg.ndvi_group_year_avg_value_list.map((ndvi_avg_value)=>ndvi_avg_value.elapse_date)
        }

        elapse_date = NAelapse_date.lengh > NYGAelapse_date ? NAelapse_date : NYGAelapse_date;
      } else {
        const polygon = this.polygons.filter((polygon) => polygon.id == this.selectPolygon)[0];
        const plantInfo = this.getPlantInfo(polygon.field.id)
        const delta_date = new Date(plantInfo.harvest_date) - new Date(plantInfo.plant_date);
        elapse_date = [...Array(delta_date / 86400000).keys()]
        elapse_date = elapse_date.map(elapse_date => {
          const e_date = new Date(plantInfo.plant_date);
          e_date.setDate(e_date.getDate() + elapse_date);
          return e_date.toISOString().substr(5,5).replaceAll("-","/");
        });
      }
      return elapse_date;
    },
    chartData() {
      const chartData = [];
      const ndviData = [];
      const ndviColor = [];
      let avgData = [];
      let avgAreaGroupData = [];
      let alertNdvi = null;

      if (!this.selectPolygon) return [];
      const polygon = this.polygons.filter((polygon) => polygon.id == this.selectPolygon)[0];

      const plantInfo = this.getPlantInfo(polygon.field.id)
      const delta_date = new Date(plantInfo.harvest_date) - new Date(plantInfo.plant_date);
      const terms = delta_date / 86400000;
      for (let elapse_date = 0; elapse_date <= terms; elapse_date++) {
        // ndvi
        const ndvi = this.ndviData.filter((ndvi)=>{
          return ndvi.elapse_date == elapse_date;
        })
        if (ndvi.length > 0) {
          ndviData.push(ndvi[0].value);
          ndviColor.push(this.getPointColor(ndvi[0]));
          if (ndvi[0].alert_status !=0) {
            alertNdvi = ndvi[0];
          } else {
            alertNdvi = null;
          }
        } else {
          ndviData.push(null);
          ndviColor.push(null);
        }

        // 平年値
        const avg = this.ndviAvg.ndvi_avg_value_list.filter((avg)=>{
          return avg.elapse_date == elapse_date;
        })

        if (avg.length > 0) {
          avgData.push(avg[0].value);
        } else {
          avgData.push(null);
        }

        // 平均値
        const group_year_avg = this.ndviAreaGroupAvg.ndvi_group_year_avg_value_list.filter((group_year_avg)=>{
          return group_year_avg.elapse_date == elapse_date;
        })

        if (group_year_avg.length > 0) {
          avgAreaGroupData.push(group_year_avg[0].value);
        } else {
          avgAreaGroupData.push(null);
        }
        
      }

      if (alertNdvi) {
        ndviColor[alertNdvi.elapse_date] = "#FF0000";
      }
      
      chartData.push({
        label: "平年値",
        data: avgData,
        borderColor: '#FF8800',
        pointBackgroundColor: '#FF8800',
        showLine: true,
        lineTension: 0.0,
        spanGaps: true,
        datalabels: {
          display: false
        },
        order: 3,
      });

      chartData.push({
        label: "平均値",
        data: avgAreaGroupData,
        borderColor: '#00AA88',
        pointBackgroundColor: '#00AA88',
        showLine: true,
        lineTension: 0.0,
        spanGaps: true,
        datalabels: {
            display: false
        },
        order: 2,
      });

      chartData.push({
        label: "NDVI",
        data: ndviData,
        borderColor: '#00FF00',
        pointBackgroundColor: ndviColor,
        pointBorderColor: ndviColor,
        showLine: false,
        datalabels: {
            display: false
        },
        order: 1,
      });
      return chartData;
    }
  }
};
</script>

<style scoped>
.plant-info-area {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.hide {
  display:none;
}
.plantInfo.selected .v-card {
  background-color: #888800 !important;
}
.v-card .v-badge {
  display: block;
}
.viewer {
  width: 100%;
}
</style>
