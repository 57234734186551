<template>
  <v-container fluid>
    <v-card class="d-flex flex-column my-6 mx-auto">
      <v-card-text class="d-flex justify-center flex-column">
        <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3">
          お問い合わせ一覧
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="検索"
          single-line
          hide-details
        ></v-text-field>
        </v-card-title>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="inquiries"
      :items-per-page="5"
      :item-key="id"
      :search="search"
      class="inquiry"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon>
          <v-icon @click.stop="onClickUpdate(item)"
            >mdi-application-edit</v-icon
          >
        </v-btn>
        <v-btn icon>
          <v-icon @click.stop="onClickDelete(item)">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <UpdateInquiryDialog ref="UpdateInquiryDialog" @reload="reload" :item="inquiries[index]"></UpdateInquiryDialog>
  </v-container>
</template>


<script>
import UpdateInquiryDialog from '@/components/comps/UpdateInquiryDialog'
import router from "../../router";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "Inquiry",
  components: {
    UpdateInquiryDialog
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
      headers: [
        { text: "対応状況", value: "status" },
        { text: "登録日時", value: "created_at" },
        { text: "ユーザー名", value: "username" },
        { text: "メールアドレス", value: "email" },
        { text: "タイトル", value: "title" },
        { text: "内容", value: "contents" },
        { text: "担当者", value: "manager" },
        { text: "", value: "actions", sortable: false },
      ],
      inquiries: [{}],
      id: "",
      index: -1,
      search: "",
    };
  },
  methods: {
    reload() {
      this.checkLoggedIn();
      axios.get("/api/admin/inquiry/").then((res) => {
        this.inquiries = res.data;
        this.id = res.data["id"];
      });
    },
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios
          .get("/api/current_user/")
          .then((res) => {
            this.$session.set("current_user", res.data);
          })
          .catch(() => {
            this.$session.destroy();
            router.push("/auth");
          });
      }
    },
    onClickDelete(item) {
      Swal.fire({
        icon: "warning",
        title: "お問い合わせの削除",
        text: "このお問い合わせを削除しますか？",
        showConfirmButton: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          axios
            .delete(`/api/admin/inquiry/${item.id}/`)
            .then(() => {
              this.loading = false;
              this.reload();
              Swal.fire({
                icon: "success",
                title: "削除完了",
                text: "削除しました",
                showConfirmButton: false,
                showCloseButton: true,
              });
            })
            .catch(() => {
              Swal.fire({
                icon: "warning",
                title: "Error",
                text: "サーバーエラーです。",
                showConfirmButton: true,
                showCloseButton: false,
                timer: 3000,
              });
            });
        }
      });
    },
    onClickUpdate(item){
      this.index = this.inquiries.indexOf(item)
      this.$refs['UpdateInquiryDialog'].showModal();
    },
  },
  computed: {},
};
</script>

<style scoped>
.searchArea {
  height: 100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.hide {
  display: none;
}
.plantInfo.selected .v-card {
  background-color: #888800 !important;
}
.v-card .v-badge {
  display: block;
}
.viewer {
  width: 100%;
}
.inquiry {
  white-space: pre-line;
}
</style>
