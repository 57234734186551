<template>
  <v-container fluid >
    <v-card class="d-flex flex-column mx-auto">
      <v-card-text class="d-flex justify-center flex-column">
        <v-card-title class="d-flex justify-center pa-0">
          ユーザー一覧
        </v-card-title>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="5"
      :search="search"
      class="inquiry"
    >
      <template v-slot:[`item.paid_user_list`]="{ item }">
        <div v-for="paidUser in item.paid_user_list" :key="paidUser.id" >
          <span>
            {{getPrimeTypeName(paidUser.prime_type)}} : {{ paidUser.expire ? new Date(paidUser.expire).toISOString().substr(0,16) : "無期限"}}
          </span>
          <br>
        </div>
      </template>
      <template v-slot:[`item.is_account`]="{ item }">
        <span>{{item.is_account ? "○" : ""}}</span>
      </template>
      <template v-slot:[`item.is_active`]="{ item }">
        <span>{{item.is_active ? "○" : ""}}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon>
          <v-icon @click.stop="onClickUpdate(item)"
            >mdi-application-edit</v-icon
          >
        </v-btn>
        <!--
        <v-btn icon>
          <v-icon @click.stop="onClickDelete(item)">mdi-delete</v-icon>
        </v-btn>
        -->
      </template>
    </v-data-table>
    <UpdateUserDialog ref="UpdateUserDialog" @reload="reload" :item="selectUser" :primeTypeList="primeTypeList"></UpdateUserDialog>
    <LoadingDialog ref="loadingDialog">
    </LoadingDialog>
  </v-container>
</template>

<script>
import UpdateUserDialog from '@/components/comps/UpdateUserDialog'
import LoadingDialog from '@/components/comps/LoadingDialog'
import router from "../../router";
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: "Users",
  components: {
    LoadingDialog,
    UpdateUserDialog
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
      selectUserId: "",
      headers: [
        { text: "ID", value: "id" },
        { text: "ユーザー名", value: "username" },
        { text: "email", value: "email" },
        { text: "郵便番号", value: "zipcode" },
        { text: "住所", value: "address" },
        { text: "課金状況 有効期限", value: "paid_user_list" },
        { text: "アカウント", value: "is_account" },
        { text: "有効", value: "is_active" },
        { text: "", value: "actions", sortable: false },
      ],
      search: "",
      primeTypeList: [],
      users: []
    }
  },
  methods: {
    reload() {
      this.checkLoggedIn();

      axios.get(`/api/admin/users/?is_customer=true`).then((res) => {
        this.users = res.data;
      });
      axios.get(`/api/prime_type_list/`).then((res) => {
        this.primeTypeList = res.data;
      });
    },
    getPrimeTypeName(primeTypeId) {
      if (!this.primeTypeList || this.primeTypeList.length < 1) return "";
      if (!primeTypeId) return "";
      return this.primeTypeList.filter((primeType) => primeType.id == primeTypeId)[0].name;
    },
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
    onClickDelete(user) {
      Swal.fire({
        icon: "warning",
        title: "ユーザーの削除",
        text: "このユーザーの削除しますか？",
        showConfirmButton: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          axios
            .delete(`/api/admin/inquiry/${user.id}/`)
            .then(() => {
              this.loading = false;
              this.reload();
              Swal.fire({
                icon: "success",
                title: "削除完了",
                text: "削除しました",
                showConfirmButton: false,
                showCloseButton: true,
              });
            })
            .catch(() => {
              Swal.fire({
                icon: "warning",
                title: "Error",
                text: "サーバーエラーです。",
                showConfirmButton: true,
                showCloseButton: false,
                timer: 3000,
              });
            });
        }
      });
    },
    onClickUpdate(user){
      this.selectUserId = user.id;
      this.$refs['UpdateUserDialog'].showModal();
    },
  },
  computed : {
    selectUser() {
      if (!this.selectUserId) return {};
      return this.users.filter((user) => user.id == this.selectUserId)[0];
    }
  }
};
</script>

<style scoped>
.fields-info-area {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.hide {
  display:none;
}
.v-card .v-badge {
  display: block;
}
.viewer {
  width: 100%;
}
</style>
