import Vue from 'vue'
import Router from 'vue-router'

import Auth from '@/components/pages/Auth'
import Top from '@/components/pages/Top'
import Viewer from '@/components/pages/Viewer'
import Inquiry from '@/components/pages/Inquiry'
import Fields from '@/components/pages/Fields'
import Users from '@/components/pages/Users'
import UserLogs from '@/components/pages/UserLogs'
import Settlement from '@/components/pages/Settlement'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Top',
      component: Top
    },
    {
      path: '/auth',
      name: 'Auth',
      component: Auth
    },
    {
      path: '/viewer',
      name: 'Viewer',
      component: Viewer
    },
    {
      path: '/inquiry',
      name: 'Inquiry',
      component: Inquiry
    },
    {
      path: '/fields',
      name: 'Fields',
      component: Fields
    },
    {
      path: '/users',
      name: 'Users',
      component: Users
    },
    {
      path: '/settlement',
      name: 'Settlement',
      component: Settlement
    },
    {
      path: '/userLogs',
      name: 'UserLogs',
      component: UserLogs
    },
  ]
})