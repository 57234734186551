<template>
  <v-container >
    <v-row class="my-3">
      <v-col sm="12" md="12" lg="4">
        <v-card class="d-flex flex-column mx-auto">
          <v-card-actions>
            <v-select
              v-model="selectUser"
              :items="usersItemList"
              :rules="[v => !!v || 'ユーザーを選択してください。']"
              label="ユーザー選択"
              v-on:change="onSelectUser"
              required
            ></v-select>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col sm="12" md="12" lg="4" v-if="isShowAddButton">
        <v-card class="d-flex flex-column mx-auto">
          <PaidUserDialog :user="currentUser" :primeTypeList="primeTypeList" @reload="reload"></PaidUserDialog>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="my-3">
      <v-col sm="12" md="12" lg="6" v-for="currentPaidUser in currentPaidUserList" :key="currentPaidUser.id">
        <v-card class="d-flex flex-column mx-auto">
          <v-card-title class="d-flex justify-right pa-5 mt-6 mb-3">
            {{getPrimeTypeName(currentPaidUser.prime_type)}}
            <br>
            有効期限： {{currentPaidUser.expire}}
            <br>
            初回決済番号:{{getRBSettlement(currentPaidUser).gid}}
            <br>
            自動課金番号:{{getRBSettlement(currentPaidUser).acid}}
            <br>
            <v-divider></v-divider>
            <PaidUserDialog :user="currentUser" :item="currentPaidUser" :primeTypeList="primeTypeList" @reload="reload"></PaidUserDialog>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row> 

    <v-data-table
      :headers="rbSettlementHeaders"
      :items="rbAutoSettlements"
      :items-per-page="5"
      item-key="rb_settle_id"
    >
      <template v-slot:[`item.date`]="{ item }">
        <span>{{ new Date(item.date).toISOString().substr(0, 16) }}</span>
      </template>

      <template v-slot:[`item.rst`]="{ item }">
        <span>{{item.rst == 1 ? "成功" : "失敗"}}</span>
      </template>

      <template v-slot:[`item.product_name`]="{ item }">
        <span>{{getPrimeType(item.settlement_result.prime_type) ? getPrimeType(item.settlement_result.prime_type).name : ""}}</span>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        <span>{{getPrimeType(item.settlement_result.prime_type) ? getPrimeType(item.settlement_result.prime_type).price : ""}}</span>
      </template>

    </v-data-table>
  </v-container>
</template>

<script>
import router from "../../router";
import axios from 'axios';
import PaidUserDialog from '@/components/comps/PaidUserDialog'

// 決済
export default {
  name: "Settlement",
  components: {
    PaidUserDialog
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
      loading: false,
      selectUser: "",
      users: [],
      usersItemList: [],
      primeTypeItemList: [],
      primeTypeList:[],
      settlements: [],
      rbSettlements: [],
      rbAutoSettlements: [],
      rbSettlementHeaders: [
        { text: "日時", value: "date" },
        { text: "商品名", value: "product_name" },
        { text: "金額", value: "price" },
        { text: "決済結果", value: "rst" },
      ],
      valid: false
    }
  },
  computed: {
    currentUser () {
      if (!this.selectUser) return {};
      if (this.users.length < 1) return {};
      return this.users.filter((user) => user.id == this.selectUser)[0];
    },
    isShowAddButton () {
      if (!this.currentPaidUserList) return false;
      if (this.currentUser.custom_user_type == 1) return this.currentPaidUserList.length < 2;
      return this.currentPaidUserList.length < 1; // masters は必ず1プラン契約
    },
    currentPaidUserList () {
      if (!this.currentUser) return [];
      return this.currentUser.paid_user_list;
    }
  },
  methods: {
    reload () {
      this.checkLoggedIn();
      axios.get('/api/prime_type_list/').then(res => {
        this.primeTypeList = res.data;
        this.primeTypeItemList = res.data.map((primeType)=>{
          return {"text": primeType.name, "value": primeType.id}
        });
      });
      axios.get('/api/admin/users/?is_customer=true').then(res => {
        this.users = res.data.filter((user) => {
          return (user.custom_user_type == 1 && user.created_masters == null)|| user.custom_user_type == 3
        });
        this.usersItemList = this.users.map((data)=>{
          return {'text':`${data.id}: ${data.username}: ${data.email}`, 'value':data.id};
        });
        if (!this.usersItemList || this.usersItemList.length < 1) return;
        if (!this.selectUser) this.selectUser = this.usersItemList[0].value;
        this.onSelectUser();
      });
    },
    validate () {
      return this.$refs.form.validate()
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    formReset () {
      return this.$refs.form.reset()
    },
    getRBSettlement(paidUser) {
      if (this.settlements.length < 1) return {};
      if (this.rbSettlements.length < 1) return {};
      const settlement = this.settlements.filter((settlement) => settlement.user == paidUser.user && settlement.prime_type == paidUser.prime_type)[0];
      if (!settlement) return {};
      return this.rbSettlements.filter((rbsettlement) => rbsettlement.cod == settlement.cod)[0];
    },
    getPrimeType(primeTypeId) {
      if (!this.primeTypeList) return;
      return this.primeTypeList.filter((primeType) => primeType.id == primeTypeId)[0];
    },
    getPrimeTypeName(primeTypeId) {
      const primeType = this.getPrimeType(primeTypeId);
      if (primeType) return primeType.name;
      return "";
    },
    onSelectUser () {
      const user = this.users.filter((user) => user.id == this.selectUser)[0];
      axios.get(`/api/admin/rb_auto_settlement_result_list/?user_id=${user.id}`).then(res => {
        this.rbAutoSettlements = res.data;
      });
      axios.get(`/api/admin/rb_settlement_result_list/?user_id=${user.id}`).then(res => {
        this.rbSettlements = res.data;
      });
      axios.get(`/api/admin/settlement_result_list/?user_id=${user.id}`).then(res => {
        this.settlements = res.data;
      });

    },
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.hidden {
  display: none;
}
</style>
