<template>
  <v-container fluid >
    <v-card class="d-flex flex-column mx-auto">
      <v-card-text class="d-flex justify-center flex-column">
        <v-card-title class="d-flex justify-center pa-0">
          圃場一覧
        </v-card-title>
      </v-card-text>
    </v-card>
    <v-row class="my-3">
      <v-col sm="12" md="12" lg="4">
        <v-card class="d-flex flex-column mx-auto">
          <v-card-actions>
            <v-select
              v-model="selectUser"
              :items="usersItemList"
              :rules="[v => !!v || 'ユーザーを選択してください。']"
              label="ユーザー選択"
              v-on:change="onSelectUser"
              required
            ></v-select>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col sm="12" md="12" lg="4">
        <v-card class="d-flex flex-column mx-auto">
          <v-text-field
            class="pa-2 pt-5"
            v-model="searchFields"
            :counter="50"
            label="圃場の検索"
            required
          ></v-text-field>
        </v-card>
      </v-col>
      <v-col sm="12" md="12" lg="4">
        <v-card class="d-flex flex-column mx-auto">
          <v-card-text class="d-flex justify-center flex-column">
            <v-card-title class="d-flex justify-center pa-0">
              圃場の数 : {{fields.length}} 
              <v-spacer></v-spacer>
              <v-btn icon
                @click.stop="downloadUserGeojson"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-card-title>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="d-flex mx-auto fields-info-area overflow-y-hidden" max-height="180">
      <v-card 
        v-for="field in displayFields"
        :key="field.id"
        min-width="220"
        class="d-flex"
      >
        <v-card-text class="d-flex justify-center flex-column">
          <v-card-subtitle class="overflow-y-auto">
            圃場名：{{ field.name }} 
            <br> ユーザー名：{{ field.user.username }}
            <br> 住所：{{ field.address }}
            <br> 備考：{{ field.description }}
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon
              @click.stop="flyTo(field.id)"
            >
              <v-icon>mdi-map-marker</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-card>
    <v-row dense class="mx-auto">
      <v-col sm="12" md="12" lg="12">
        <div class="viewer">
          <vc-viewer @ready="ready" :infoBox="false" :selectionIndicator="false">
            <vc-layer-imagery>
              <vc-provider-imagery-arcgis-mapserver :url="url" />
            </vc-layer-imagery>
            <vc-entity v-for="polygon in displayPolygons" :key="polygon.id" @click="onPolygonClick(polygon)">
              <vc-graphics-polygon
                :hierarchy="convertHierarchy(polygon)"
                :material="[0.1, 1, 0.2, 0.5]"
                :extrudedHeight="0.0"
                :closeTop="false"
                :closeBottom="false"
                :ref="polygonRef(polygon.id)"
                :outline="true"
                :outlineColor="[0.1, 1, 0.2, 1]"
                :outlineWidth="5"
              ></vc-graphics-polygon>
            </vc-entity>
          </vc-viewer>
        </div>
      </v-col>
    </v-row>
    <LoadingDialog ref="loadingDialog">
    </LoadingDialog>
  </v-container>
</template>

<script>
import LoadingDialog from '@/components/comps/LoadingDialog'
import router from "../../router";
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: "Fields",
  components: {
    LoadingDialog
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
      url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer",
      Cesium: {},
      viewer: {},
      camera: {},
      selectUser: "",
      users: [],
      usersItemList: [],
      selectPolygon: "",
      fields: [],
      polygons: [],
      searchFields: ""
    }
  },
  methods: {
    ready(cesiumInstance) {
      const { Cesium, viewer } = cesiumInstance;
      viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      this.Cesium = Cesium;
      this.viewer = viewer;
    },
    reload() {
      this.checkLoggedIn();

      axios.get(`/api/admin/users/?is_customer=true&custom_user_type=1`).then((res) => {
        this.users = res.data;
        this.usersItemList = res.data.map((data)=>{
          return {'text':`${data.id}: ${data.username}: ${data.email}`, 'value':data.id};
        });
        
        if (!this.usersItemList || this.usersItemList.length < 1) return;

        this.selectUser = this.usersItemList[0].value;
        this.onSelectUser();
      });
    },
    onSelectUser() {
      const user = this.users.filter((user) => user.id == this.selectUser)[0];
      this.$refs['loadingDialog'].showModal();
      Promise.all([
        axios.get(`/api/fields/?user_id=${this.selectUser}`).then((res) => {
          this.fields = res.data;
        }),
        axios.get(`/api/geoapi_from_postal/?zipcode=${user.zipcode}`).then((res) => {
          this.zoomX = res.data.x;
          this.zoomY = res.data.y;
          this.zoom();
        }),
        axios.get(`/api/polygon_detail/?user_id=${this.selectUser}`).then((res) => {
          this.polygons = res.data;
          this.polygons.forEach((polygon) => {
            polygon.poslist.sort((a, b) => a.sequence - b.sequence);
          })
        })
      ]).then(()=>{
        this.$refs['loadingDialog'].closeModal();
      });
    },
    zoom(){
      if (Object.keys(this.viewer).length){
        this.viewer.camera.flyTo({destination:this.Cesium.Cartesian3.fromDegrees(this.zoomX, this.zoomY, 12000.0)})
      } else {
        setTimeout(this.zoom, 100);
      }
    },
    getPolygon(field_id) {
      return this.polygons.filter((polygon)=>{
        return polygon.field && polygon.field.id == field_id;
      });
    },
    flyTo(field_id) {
      const result = this.getPolygon(field_id);
      const pos = result[0].poslist[0];
      this.viewer.camera.flyTo({destination:this.Cesium.Cartesian3.fromDegrees(pos.lng, pos.lat, 1000.0)});
    },
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
    convertHierarchy(polygon) {
      return polygon.poslist.map((pos) => {
        return { "lng": pos.lng, "lat": pos.lat, height: 0 }
      });
    },
    polygonRef(polygon_id) {
      return `polygon_${polygon_id}`;
    },
    onPolygonClick(polygon) {
      console.log(polygon);
    },
    downloadUserGeojson() {
      axios.get(`/api/user_geo_json/?user_id=${this.selectUser}`).then((res) => {
        if (res.data[0]) {
          // Blobオブジェクト生成
          const jsonBlob = new Blob([res.data], {type : "application/json;charset=utf-8;"});
          this.downloadBlob(jsonBlob, name, "geojson");
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'データを取得できませんでした',
            showConfirmButton:false,
            showCloseButton:false,
            timer:3000
          })
        }
      }).catch(()=>{
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          text: 'サーバーエラーが発生しました',
          showConfirmButton:false,
          showCloseButton:false,
          timer:3000
        })
      })
    },
    downloadBlob (blob, title, ext) {
      const downloadUrl = window.URL.createObjectURL(blob);

      // ダウンロードリンク生成
      const link = document.createElement("a");
      link.href = downloadUrl;

      // ファイル名
      link.download =
          `${title}`
          + "." + ext;

      // ダウンロード実行
      document.body.appendChild(link);
      link.click();

      // ダウンロードリンク破棄
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    },
  },
  computed : {
    displayFields () {
      return this.fields.filter((field) => {
        if (field.name && field.name.includes(this.searchFields)) {
          return true;
        }
        if (field.address && field.address.includes(this.searchFields)) {
          return true;
        }
        if (field.description && field.description.includes(this.searchFields)) {
          return true;
        }
        return false;
      });
    },
    displayPolygons () {
      if (!this.selectUser) return [];
      const displayPolygon = this.polygons.filter((polygon) => {
        return polygon.field.user.id == this.selectUser;
      });
      return displayPolygon;
    },
  }
};
</script>

<style scoped>
.fields-info-area {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.hide {
  display:none;
}
.v-card .v-badge {
  display: block;
}
.viewer {
  width: 100%;
}
</style>
