<script>
import { Line, mixins } from "vue-chartjs"
import * as datalabels from 'chartjs-plugin-datalabels'

export default {
  extends: Line,
  mixins: [mixins.reactiveData],
  props: {
    propData: {
      type: Array,
      default: null
    },
    labels: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      options: {
        legend:{
          labels: {
            fontColor: "rgba(0,0,0,0.7)"
          }
        },
        scales:{
          xAxes:[{
            gridLines: {
              color: "rgba(0,0,0,0.1)",
              fontColor: "rgba(0,0,0,0.5)"
            },
            ticks:{
              fontColor: "rgba(0,0,0,0.7)"
            }
          }],
          yAxes: [{
            gridLines: {
              color: "rgba(0,0,0,0.1)",
              fontColor: "rgba(0,0,0,0.5)"
            },
            ticks:{
              fontColor: "rgba(0,0,0,0.7)",
              autoSkip: true,
              maxTicksLimit: 10 //値の最大表示数
            }
          }]
        },
        plugins:{
          datalabels: {
            formatter: function(value, context) {
                return context.chart.data.labels[context.dataIndex];
            },
            font: {
              weight: 'bold',
              size: 16,
            }
          }
        },
        onClick: this.onChartClick
      }
    }
  },
  mounted () {
    this.addPlugin(datalabels)
    this.render()
  },
  watch: {
    propData () {
      this.render()
    },
    labels () {
      this.render()
    }
  },
  methods: {
    render () {
      this.chartData = {
        labels: this.labels,
        options: this.options,
        datasets: this.propData
      }
    },
    onChartClick (event, tooltipItems) {
      this.$emit('onChartClick', event, tooltipItems);
    }
  }
}
</script>

<style>
</style>