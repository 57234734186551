<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title>
          <span class="text-h5">お問い合わせの更新</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="status"
                  :items="items"
                  label="対応状況"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="created_at"
                  readonly
                  label="登録日時"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="username"
                  readonly
                  label="ユーザー名"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  readonly
                  label="メールアドレス"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="title"
                  readonly
                  label="タイトル"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="contents"
                  readonly
                  label="内容"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="manager"
                  :counter="50"
                  :rules="managerRules"
                  label="担当者"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            @click="onClickDelete"
          >
            閉じる
          </v-btn>
          <v-btn
            color="primary"
            dark
            @click="submit"
          >
            更新
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

// お問い合わせ更新ダイアログ
export default {
  name: "UpdateInquiryDialog",
  components: {
  },
  props: {
    item: Object(),
    item_key: String,
  },
  mounted() {
    this.loading = true;
  },
  data() {
    return {
      status: '',
      items: ['未対応', '対応中', '完了'],
      manager: '',
      managerRules: [
        v => (String(v).length <= 256) || '担当者 は 256文字以内で入力してください',
      ],
      created_at: '',
      username: '',
      email: '',
      title: '',
      contents: '',
      valid: false,
      dialog: false
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.item) {
          this.status = this.item["status"];
          this.created_at = this.item["created_at"];
          this.username = this.item["username"];
          this.email = this.item["email"];
          this.title = this.item["title"];
          this.contents = this.item["contents"];
          this.manager = this.item["manager"];
        }
      }
    }
  },
  computed: {
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    formReset () {
      return this.$refs.form.reset()
    },
    showModal () {
      this.dialog = true;
    },
    closeModal () {
      this.dialog = false;
    },
    submit() {
      if (this.validate()) {
        this.loading = true;
        this.key = this.item["id"]
        // const current_user = this.$session.get('current_user');
        axios.patch(`/api/admin/inquiry/${this.key}/`, {
          'status': this.status,
          'manager': this.manager,
        }).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          Swal.fire({
            icon: 'info',
            title: '更新完了',
            text: 'お問い合わせの更新が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
          this.$emit('reload')
        }).catch(() => {
          this.loading = false;
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
        })
      }
    },
    onClickDelete() {
      Swal.fire({
        icon: 'warning',
        title: '変更途中の退出',
        text: '変更が保存されないまま閉じられようとしていますがよろしいですか？',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = false
        }
      })
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
