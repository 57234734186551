<template>
  <v-container fluid >
    <v-card class="d-flex flex-column mx-auto">
      <v-card-text class="d-flex justify-center flex-column">
        <v-card-title class="d-flex justify-center pa-0">
          ユーザーログ
        </v-card-title>
      </v-card-text>
    </v-card>
    <v-row class="my-3">
      <v-col sm="12" md="12" lg="4">
        <v-card class="d-flex flex-column mx-auto">
          <v-card-actions>
            <v-select
              v-model="selectUserId"
              :items="usersItemList"
              :rules="[v => !!v || 'ユーザーを選択してください。']"
              label="ユーザー選択"
              v-on:change="onSelectUser"
              required
            ></v-select>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="userLogs"
      :items-per-page="5"
      :search="search"
    >
      <template v-slot:[`item.user_id`]="{ item }">
        <span>
          {{item.user}}
        </span>
        <br>
      </template>
      <template v-slot:[`item.username`]="{ item }">
        <span>
          {{getUser(item.user).username}}
        </span>
        <br>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <span>
          {{item.action}}
        </span>
        <br>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <span>
          {{item.description}}
        </span>
        <br>
      </template>
    </v-data-table>
    <LoadingDialog ref="loadingDialog">
    </LoadingDialog>
  </v-container>
</template>

<script>
import LoadingDialog from '@/components/comps/LoadingDialog'
import router from "../../router";
import axios from 'axios';

export default {
  name: "UserLogs",
  components: {
    LoadingDialog,
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
      selectUserId: "",
      headers: [
        { text: "ユーザーID", value: "user_id" },
        { text: "ユーザー名", value: "username" },
        { text: "動作", value: "action" },
        { text: "備考", value: "description" },
        { text: "時刻", value: "date" },
      ],
      users: [],
      usersItemList: [],
      userLogs: [],
      search: "",
      primeTypeList: []
    }
  },
  methods: {
    reload() {
      this.checkLoggedIn();

      axios.get(`/api/admin/users/?is_customer=true`).then((res) => {
        this.users = res.data;
        this.usersItemList = res.data.map((data)=>{
          return {'text':`${data.id}: ${data.username}: ${data.email}`, 'value':data.id};
        });
        this.selectUserId = this.usersItemList[0].value;
        this.onSelectUser();
      });
    },
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
    getUser(user_id) {
      return this.users.filter((user) => user.id == user_id)[0];
    },
    onSelectUser(){
      axios.get(`/api/admin/user_logs/?user_id=${this.selectUserId}`).then((res) =>{
        this.userLogs = res.data;
      });
    }
  },
  computed : {
    selectUser() {
      if (!this.selectUserId) return {};
      return this.getUser(this.selectUserId);
    }
  }
};
</script>

<style scoped>
.fields-info-area {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.hide {
  display:none;
}
.v-card .v-badge {
  display: block;
}
.viewer {
  width: 100%;
}
</style>
