<template>
  <v-container fluid >
    <v-card class="d-flex flex-column my-6 mx-auto">
      <v-card-text class="d-flex justify-center flex-column">
        <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3">
          トップページ
        </v-card-title>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import router from "../../router";
import axios from 'axios';

export default {
  name: "Top",
  components: {
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
    }
  },
  methods: {
    reload() {
      this.checkLoggedIn();
    },
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
  },
  computed : {
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.hide {
  display:none;
}
.plantInfo.selected .v-card {
  background-color: #888800 !important;
}
.v-card .v-badge {
  display: block;
}
.viewer {
  width: 100%;
}
</style>
