<template>
    <v-container grid-list-md>
        <v-layout row wrap align-center justify-center fill-height>
            <v-flex>
                <v-card class="login-card">
                    <v-card-title>
                    <span class="headline">GrowthWatcher管理画面ログイン</span>
                    </v-card-title>

                    <v-spacer/>

                    <v-card-text>

                    <v-layout
                        row
                        fill-height
                        justify-center
                        align-center
                        v-if="loading"
                    >
                        <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                        />
                    </v-layout>


                    <v-form v-else ref="form" v-model="valid" lazy-validation>
                        <v-container>

                        <v-text-field
                            v-model="credentials.email"
                            :counter="254"
                            label="メールアドレス"
                            :rules="rules.email"
                            maxlength="70"
                            required
                        />

                        <v-text-field
                            type="password"
                            v-model="credentials.password"
                            :counter="20"
                            label="パスワード"
                            :rules="rules.password"
                            maxlength="20"
                            required
                        />

                        </v-container>
                        <v-card-text class="text-center">
                          <v-btn class="pink white--text" :disabled="!valid" @click="login">Login</v-btn>
                        </v-card-text>
                    </v-form>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import router from '../../router';
export default {
    name: 'Auth',
    mounted(){
    },
    data(){
      return {
        credentials: {},
        valid:true,
        loading:false,
        rules: {
          email: [
            v => !!v || 'メールアドレス が 必要です',
            v => /.+@.+\..+/.test(v) || 'メールアドレス の形式が正しくありません',
          ],
          password: [
            v => !!v || "パスワードは必須です",
            v => (v && v.length > 3) || "パスワードは4文字以上でなければなりません"
          ]
        }
      };
    },
    methods: {
      login() {
        if (this.$refs.form.validate()) {
          this.loading = true;
          axios.post('/api/admin-auth/', this.credentials).then(res => {
            this.$session.start();
            this.$session.set("token", res.data.token);
            axios.defaults.headers.common[
              "Authorization"
            ] = `JWT ${res.data.token}`;
            axios.get('/api/current_user/').then(res => {
              this.$session.set('current_user',res.data);
              router.push("/");
            })
          // eslint-disable-next-line
          }).catch(e => {
            console.log(e);
            const error_message = e.response.data.non_field_errors[0]
            console.log(error_message);
            console.log(error_message == "User account is not staff.");
            if (error_message == "User account is not staff.") {
              this.loading = false;
              Swal.fire({
                icon: 'warning',
                title: 'Error',
                text: '対象のユーザーはスタッフではありません。',
                showConfirmButton:false,
                showCloseButton:false,
                timer:3000
              })
            } else {
              this.loading = false;
              Swal.fire({
                icon: 'warning',
                title: 'Error',
                text: 'ユーザー名もしくはパスワード、または両方が間違っています',
                showConfirmButton:false,
                showCloseButton:false,
                timer:3000
              })
            }
          })
        }
      },
    }
}
</script>
