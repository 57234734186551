<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title>
          <span class="text-h5">ユーザー情報の更新</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="username"
                  readonly
                  label="ユーザー名"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="email"
                  readonly
                  label="email"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="zipcode"
                  label="郵便番号"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="address"
                  label="住所"
                ></v-text-field>
              </v-col>
              <!--
              <v-col cols="12" v-for="(primeType, index) in primeTypeList" :key="primeType.id">
                <v-select
                  v-model="selectPrimeTypeList[index]"
                  :items="isActiveItems" 
                  :label="primeType.name"
                ></v-select>
                <v-date-picker
                  v-model="plantDate"
                  :allowed-dates="isAllowedPlantDates"
                  :show-current="showCurrentDates"
                >
                </v-date-picker>
              </v-col>
              -->
              <v-col cols="12">
                <v-select
                  v-model="isActive"
                  :items="isActiveItems" 
                  label="有効"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            @click="onClickDelete"
          >
            閉じる
          </v-btn>
          <v-btn
            color="primary"
            dark
            @click="submit"
          >
            更新
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

// ユーザー情報更新ダイアログ
export default {
  name: "UpdateUserDialog",
  components: {
  },
  props: {
    item: Object(),
    primeTypeList: Array()
  },
  mounted() {
    this.loading = true;
  },
  data() {
    return {
      username: '',
      email: '',
      zipcode: '',
      address: '',
      isActive: false,
      isActiveItems: [
        {'text':"有効", 'value':true},
        {'text':"無効", 'value':false}
      ],
      selectPrimeTypeList: [],
      selectPrimeTypeExpireList: [],
      valid: false,
      dialog: false
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.item) {
          this.username = this.item["username"];
          this.email = this.item["email"];
          this.zipcode = this.item["zipcode"];
          this.address = this.item["address"];
          this.isActive = this.item["is_active"];
          this.selectPrimeTypeList = [];
          this.primeTypeList.forEach((primeType) => {
            this.selectPrimeTypeList.push(this.getPaidUserValue(this.item["paid_user_list"], primeType.id));
          });
        }
      }
    }
  },
  computed: {
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    formReset () {
      return this.$refs.form.reset()
    },
    showModal () {
      this.dialog = true;
    },
    closeModal () {
      this.dialog = false;
    },
    getPaidUserValue(paidUserList, primeTypeId) {
      if (!paidUserList && paidUserList.length < 1) return;
      const filterValue = paidUserList.filter((paidUser) => paidUser.prime_type == primeTypeId);
      if (filterValue.length < 1) return;
      return filterValue[0];
    },
    submit() {
      if (this.validate()) {
        this.loading = true;
        const userId = this.item["id"]
        axios.patch(`/api/admin/users/${userId}/`, {
          'zipcode': this.zipcode,
          'address': this.address,
          'is_active': this.isActive,
        }).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          Swal.fire({
            icon: 'info',
            title: '更新完了',
            text: 'ユーザーの更新が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
          this.$emit('reload')
        }).catch(() => {
          this.loading = false;
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
        })
      }
    },
    onClickDelete() {
      Swal.fire({
        icon: 'warning',
        title: '変更途中の退出',
        text: '変更が保存されないまま閉じられようとしていますがよろしいですか？',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = false
        }
      })
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
