<template>
  <v-app>
    <v-app-bar
        app
        v-if="isMenu"
      >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title>GrowthWatcher管理サイト</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="isMenu">{{displayName}}</v-toolbar-title>
    </v-app-bar>
    <!-- ここから -->
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group>
          <v-list-item @click.stop="selectItem('')">
            <v-list-item-title>トップ</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="selectItem('users')">
            <v-list-item-title>ユーザー一覧</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="selectItem('userLogs')">
            <v-list-item-title>ユーザーログ</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="selectItem('fields')">
            <v-list-item-title>圃場データの一覧</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="selectItem('viewer')">
            <v-list-item-title>登録NDVIデータの閲覧</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="selectItem('inquiry')">
            <v-list-item-title>お問い合わせの閲覧</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="selectItem('settlement')">
            <v-list-item-title>決済履歴</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="logout()">
            <v-list-item-title>ログアウト</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import router from "./router";

export default {
  name: 'App',
  mounted(){
    this.isMenu = this.$session.has("token");
    this.currentUser = this.$session.get('current_user');
  },
  data(){
    return {
      drawer: false,
      isMenu: false,
      currentUser: {}
    }
  },
  watch: {
    $route() {
      this.isMenu = this.$session.has("token");
      this.currentUser = this.$session.get('current_user');
    }
  },
  methods: {
    selectItem(path){
      this.drawer = false;
      router.push(`/${path}`).catch(()=>{
        location.reload();
      });
    },
    logout(){
      this.$session.destroy();
      router.push("/auth");
    }
  },
  computed: {
    displayName() {
      if (!this.currentUser) return '';
      return `${this.currentUser.username}`;
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
